<template>
  <div class="menu-links">
    <div class="menu-link retour-style">
      <router-link class="menu-item" to="/">
        <img
          src="../../assets/Icon ionic-md-arrow-dropright-circle.png"
          class="flesh-icon"
        />
        <span>Retour</span></router-link
      >
    </div>
    <div class="menu-link">
      <router-link class="menu-item" to="/setting/personal-info">
        Informations personnelles</router-link
      >
    </div>
    <div class="menu-link" v-if="isAdminRegie">
      <router-link class="menu-item" to="/setting/regie-info">
        Informations de régie
      </router-link>
    </div>
    <div class="menu-link" v-if="isAdminRegie">
      <router-link class="menu-item" to="/setting/file">
        Liste des documents
      </router-link>
    </div>
    <div class="menu-link" v-if="isSuperAdmin && computedIsoCurenType">
      <router-link class="menu-item" to="/setting/gestion"
        >Gestion organisation</router-link
      >
    </div>
    <div class="menu-link" v-if="isSuperAdmin && computedIsoCurenType">
      <router-link class="menu-item" to="/setting/configuration"
        >Gestion organisation par semaine</router-link
      >
    </div>
    <div class="menu-link" v-if="isSuperAdmin">
      <router-link class="menu-item" to="/setting/configurationfiche"
        >Gestion fiche</router-link
      >
    </div>
    <div class="menu-link" v-if="isSuperAdmin && computedIsoCurenType">
      <router-link class="menu-item" to="/setting/paies"
        >Gestion des paies</router-link
      >
    </div>
    <!-- <div class="menu-link" v-if="isSuperAdmin && computedThCurenType">
      <router-link class="menu-item" to="/setting/gestion-variable-th"
        >Gestion des variables</router-link
      >
    </div> -->
    <div class="menu-link" v-if="isSuperAdmin && computedIsoCurenType">
      <router-link class="menu-item" to="/setting/tarif-regie"
        >Gestion des tarifs régie</router-link
      >
    </div>
    <!-- <div class="menu-link" v-if="isSuperAdmin && computedThCurenType">
      <router-link class="menu-item" to="/setting/tarif-regie-th"
        >Gestion des tarifs régie</router-link
      >
    </div> -->
    <div class="menu-link" v-if="isAdminRegie">
      <router-link class="menu-item" to="/setting/tarif-sous-regie"
        >Gestion des tarifs sous régies</router-link
      >
    </div>
    <div class="menu-link" v-if="isSuperAdmin && computedIsoCurenType">
      <router-link class="menu-item" to="/setting/calender"
        >Calendrier</router-link
      >
    </div>
    <div
      class="menu-link"
      v-if="
        (isSuperAdmin || getPermissionInterface('Calendrier TH')) &&
          computedThCurenType
      "
    >
      <router-link class="menu-item" to="/setting/calender-th"
        >Calendrier TH
      </router-link>
    </div>
    <custom-drop-down
      v-if="(isSuperAdmin || permisssionGestionFrais) && computedThCurenType"
      title="Gestion des variable"
      :items="itemsGestionVariable"
    ></custom-drop-down>
    <div
      class="menu-link"
      v-if="
        (isSuperAdmin || getPermissionInterface('Répartition des zones')) &&
          computedThCurenType
      "
    >
      <router-link class="menu-item" to="/setting/repartition-zone-th"
        >Répartition des zones
      </router-link>
    </div>
    <div
      class="menu-link"
      v-if="
        (isSuperAdmin || getPermissionInterface('Gestion des sociétés')) &&
          computedThCurenType
      "
    >
      <router-link class="menu-item" to="/setting/gestion-filiale-th"
        >Gestion des sociétés
      </router-link>
    </div>
    <div class="menu-link" v-if="isSuperAdmin && computedThCurenType">
      <router-link class="menu-item" to="/setting/gestion-configuration">
        Gestion des configurations
      </router-link>
    </div>
    <!-- <div
      class="menu-link"
      v-if="
        (isSuperAdmin || getPermissionInterface('Gestion des sociétés SCI')) &&
          computedThCurenType
      "
    >
      <router-link class="menu-item" to="/setting/gestion-societe-filiale-sci"
        >Gestion des sociétés SCI
      </router-link>
    </div> -->
    <!-- <div
      class="menu-link"
      v-if="
        (isSuperAdmin || getPermissionInterface('Gestion des organismes')) &&
          computedThCurenType
      "
    >
      <router-link class="menu-item" to="/setting/organisme-th">
        Gestion des organismes
      </router-link>
    </div> -->
    <div
      class="menu-link"
      v-if="
        (isSuperAdmin || getPermissionInterface('Gestion des organismes')) &&
          computedThCurenType
      "
    >
      <router-link class="menu-item" to="/setting/organisme-new-th">
        Gestion des organismes
      </router-link>
    </div>
    <div
      class="menu-link"
      v-if="
        (isSuperAdmin || getPermissionInterface('Gestion des templates')) &&
          computedThCurenType
      "
    >
      <router-link class="menu-item" to="/setting/template-th">
        Gestion des templates
      </router-link>
    </div>
    <div
      class="menu-link"
      v-if="isSuperAdmin || getPermissionInterface('Gestion des utilisateurs')"
    >
      <router-link
        class="menu-item"
        :class="{ 'router-link-exact-active': computedRouterUserActive }"
        to="/setting/users"
        >Gestion des utilisateurs</router-link
      >
    </div>
    <custom-drop-down
      v-if="(isSuperAdmin || permisssionFactureLibre) && computedThCurenType"
      title="Gestion des factures libre"
      :items="itemsGestionFactureLibre"
    ></custom-drop-down>
    <div
      class="menu-link"
      v-if="isSuperAdmin || getPermissionInterface('Gestion des vues')"
    >
      <router-link class="menu-item" to="/setting/vue"
        >Gestion des vues</router-link
      >
    </div>
    <!-- <div
      class="menu-link"
      v-if="isSuperAdmin || getPermissionInterface('Gestion des vues')"
    >
      <router-link class="menu-item" to="/setting/dashboard-factures"
        >Moniteur des factures</router-link
      >
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import customDropDown from '../component/customDropDown.vue';
export default {
  components: { customDropDown },
  data() {
    return {
      itemsGestionVariable: [
        { name: 'Gestion des variable', href: '/setting/gestion-variable-th' },
        { name: 'Gestion des tarifs régie', href: '/setting/tarif-regie-th' },
        {
          name: 'Gestion des variables frais',
          href: '/setting/gestion-params-frais-th'
        },
        // {
        //   name: 'Gestion des variables frais par filiale',
        //   href: '/setting/gestion-params-frais-par-filiale-th'
        // },
        // {
        //   name: 'Gestion Commercial Cactus',
        //   href: '/setting/gestion-params-frais-cactus-alteo-th'
        // },
        {
          name: 'Gestion des variables responsable filiale',
          href: '/setting/gestion-params-frais-responsable-filiale-th'
        }
      ],
      itemsGestionFactureLibre: [
        { name: 'Gestion des catégories', href: '/setting/gestion-categories' },
        { name: 'Gestion des produits', href: '/setting/gestion-produit' }
      ]
    };
  },
  computed: {
    ...mapGetters(['getUserData', 'getCurrentType', 'getPermissionInterface']),
    permisssionGestionFrais() {
      return (
        this.getPermissionInterface('Gestion des variable') ||
        this.getPermissionInterface('Gestion des variables frais') ||
        this.getPermissionInterface('Gestion des variables responsable filiale')
      );
    },
    permisssionFactureLibre() {
      return (
        this.getPermissionInterface('Gestion des catégories') ||
        this.getPermissionInterface('Gestion des produits')
      );
    },
    computedRouterUserActive() {
      return (
        this.$route.path === '/setting/users/permissions' ||
        this.$route.path === '/setting/users/users'
      );
    },
    computedIsoCurenType: function() {
      return (
        this.getCurrentType &&
        this.getCurrentType.value &&
        this.getCurrentType.value === 'iso'
      );
    },
    computedThCurenType: function() {
      return (
        this.getCurrentType &&
        this.getCurrentType.value &&
        this.getCurrentType.value === 'Th'
      );
    },
    isSuperAdmin: function() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'admin'
      );
    },
    isAdminRegie: function() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'admin_regie'
      );
    }
  }
};
</script>
<style scoped lang="scss">
.menu-links {
  border-radius: 25px;
  margin: 1px 0px;
  padding: 0px 0px 6px 4px;

  .retour-style {
    span {
      margin-left: 10px;
    }
  }
  .menu-link {
    font-size: 12px;
    .flesh-icon {
      width: 23px;
    }
    a.menu-item {
      border-radius: 21px 0px 0px 21px;
      display: block;
      font-size: 12px;
      font-weight: 500;
      color: #495057;
      margin: 4px 0;
      padding: 5px 10px;
      text-decoration: none;
      background-color: #ffffff;
      font-family: 'Montserrat', sans-serif;
      &.router-link-exact-active {
        color: #fff;
        background-color: #4d4bac;
      }
      &:hover {
        color: #fff;
        background-color: #9799d6;
      }
      span {
        margin-top: 2px;
      }
    }
  }
}
</style>
